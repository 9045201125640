<template>
    <div class="reg-box">
        <div class="reg-title">
            <div class="reg-ch-title">小程序信息设置</div>
            <div class="reg-en-title">Mini program Info</div>
        </div>
        <div>
            <div class="ast-reg-steps">
                <div class="ast-reg-step active">1</div>
                <div class="ast-reg-step active">2</div>
                <div class="ast-reg-step">3</div>
                <div class="ast-reg-step">4</div>
                <div class="ast-reg-step">5</div>
            </div>
            <div class="cm-form">
                <div class="cm-item">
                    <div class="cm-title">小程序图标</div>
                    <div class="flex flex-left-center">
                        <upload-image :disabled="info.head_img" v-model="params.head_img" usage="reg-info"/>
                        <div class="tips">
                            <div>格式要求：图片格式只支持：BMP、JPEG、JPG、GIF、PNG，大小不超过 2M。</div>
                            <div class="mb-4">实际头像始终为正方形</div>
                            <div v-if="params.head_img">
                                <a @click=" showHeadModal  = true ">预览头像</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="cm-item">
                    <div class="cm-title">小程序介绍</div>
                    <div>
                        <a-textarea :readonly="info.signature" v-model="params.signature"
                                    class="at-textarea summary-area" :maxLength="120"/>
                        <div class="mt-4 tips">最少4个字，最多120个字</div>
                    </div>
                </div>

                <div>
                    <button @click="confirmApply" class="btn">提交</button>
                </div>

            </div>

        </div>


        <div>

        </div>


        <a-modal width="640px" title="小程序头像预览" v-model="showHeadModal" :footer="null">
            <div class="head-box">
                <div class="head-img-1">
                    <template v-if="params.head_img">
                        <img :src="params.head_img" alt="">
                    </template>
                </div>
                <div class="head-img-2">
                    <div class="head-img-title">头像预览</div>
                    <div class="head-img-2-box">

                        <div class="head-img-2-1">
                            <template v-if="params.head_img">
                                <img :src="params.head_img" alt="">
                            </template>
                        </div>

                        <div class="head-img-2-2">
                            <template v-if="params.head_img">
                                <img :src="params.head_img" alt="">
                            </template>
                        </div>

                    </div>
                </div>
            </div>

        </a-modal>
    </div>
</template>

<script>
import api from "@/repo/api";
import UploadImage from "@/components/UploadImage.vue";

export default {
    name: "Info",
    components: {UploadImage},
    data() {
        return {
            params: {
                head_img: '',
                signature: '',
            },
            showHeadModal: false,
            info: {
                head_img: false,
                signature: false
            },
        }
    },
    methods: {
        confirmApply() {

            this.$loading.show()


            api.post('/ast-reg/edit-head-image-signature', this.params, (data) => {

                this.$loading.hide();


                if (data.code !== 0) {

                    this.$message.data(data)

                    return
                }

                this.info = data.data;

                if (this.info.has_error) {
                    return this.$message.error(this.info.msg || '操作失败');
                }

                this.$emit('success',data);
            });
        }
    }
}
</script>

<style lang="less" scoped>

.reg-selections {
    margin-top: 72px;

}

.icon-box {
    width: 80px;
    height: 80px;
    background: #F5F5F5 url("/assets/icons/xcx-icon-black.png") no-repeat center;
    background-size: 32px;
    margin-right: 16px;

    &.green {
        background: rgba(6, 222, 107, 0.2) url("/assets/icons/xcx-icon-green.png") no-repeat center;
        background-size: 32px;
    }
}

.reg-selection {
    border: 1px solid #EEEEEE;
    padding: 20px;
    width: 440px;
    margin-bottom: 32px;

    background-image: url("/assets/icons/ast-reg-right.png");
    background-repeat: no-repeat;
    background-size: 74px;
    background-position: 326px 79px;

    display: flex;


    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;

    cursor: pointer;
}

.selection-title {
    margin-top: 12px;
    margin-bottom: 12px;
}

.company-reg {
    margin-top: 72px;
    margin-left: 120px;
}

.company-reg-title {
    width: 177px;
    height: 64px;

    background-image: url("/assets/icons/compang-reg-title.png");
    background-size: 177px;
}

.ast-reg-steps {
    margin-bottom: 32px;
}

.summary-area {
    height: 120px;
}

.head-box {
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    img {
        width: 100%;
        height: 100%;
    }
}

.head-img-title {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
}

.head-img-1 {
    width: 200px;
    height: 200px;
    background: #F5F5F5;
    margin-right: 24px;
    overflow: hidden;
}

.head-img-2 {
    background: #F5F5F5;
    flex: 1;
    height: 200px;
    padding-left: 24px;
}

.head-img-2-box {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.head-img-2-1 {
    width: 144px;
    height: 144px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-right: 28px;
    overflow: hidden;
}

.head-img-2-2 {
    width: 144px;
    height: 144px;
    background: #FFFFFF;
    border-radius: 50%;
    overflow: hidden;
}

</style>