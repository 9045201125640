<template>
    <div class="reg-box" :class="{com}">
        <div class="reg-title" v-if="!com">
            <div class="reg-ch-title">小程序名称认证</div>
            <div class="reg-en-title">Official WeChat certification</div>
        </div>
        <div>
            <div class="ast-reg-steps" v-if="!com">
                <div class="ast-reg-step active">1</div>
                <div class="ast-reg-step active">2</div>
                <div class="ast-reg-step active">3</div>
                <div class="ast-reg-step">4</div>
                <div class="ast-reg-step">5</div>
            </div>
            <div class="cm-form" v-if="status === 'waite' ">

                <div class="cm-item">
                    <div class="mb-2">微信认证账号命名规则</div>
                    保护注册商标原则：账号名不得侵犯注册商标专用权，否则审核将不被通过或面临法律诉讼；
                    认证命名唯一原则：账号名不得与已认证账号重复，否则审核将不被通过。
                </div>
                <div class="cm-item">
                    <div class="cm-title">小程序认证名称</div>
                    <at-input v-model="authData.account_name" class="full-with"/>
                    <div class="tips mt-4">
                        <span>如：Nikke中国 </span>
                        <a href="https://static.ycsd.work/collocation-helper/ast/nickname-tips.png"
                           target="_blank">查看示例</a>
                    </div>
                </div>

                <div class="cm-item">
                    <div class="cm-title">命名方式</div>
                    <div>
                        <a-radio-group v-model="authData.account_name_type">
                            <a-radio :value="1">基于自选词汇命名</a-radio>
                            <a-radio :value="2">基于商标命名</a-radio>
                        </a-radio-group>
                    </div>
                </div>

                <template v-if=" authData.account_name_type === 2 ">
                    <div class="cm-item">
                        <div class="cm-title">商标注册证书</div>
                        <div class="flex flex-left-center">
                            <upload-image v-model="authData .qualification" usage="reg-info"/>
                            <div class="tips">格式要求：原件照片、扫描件或者加盖公章的复印件，支持.jpg .jpeg .bmp .gif
                                .png格式照片，大小不超过5M。
                            </div>
                        </div>

                    </div>

                    <div class="cm-item">
                        <div class="cm-title">
                            <span>商标授权书</span>
                            <small>(可选)</small>
                        </div>
                        <div class="flex flex-left-center">
                            <upload-image v-model="authData.qualification_other" multiple usage="reg-info"/>
                            <div class="tips">若商标注册书上的商持方和认证主体不一致，请提供商标授权书。商标授权书可上传最多5张
                                格式要求：原件照片、扫描件或者加盖公章的复印件，支持.jpg .jpeg .bmp .gif .png格式照片，大小不超过5M。
                            </div>
                        </div>

                    </div>
                </template>

                <div class="cm-item">
                    <div class="cm-title">认证类型</div>
                    <div>
                        <a-radio-group v-model="authData.customer_type">
                            <a-radio :value="1">企业</a-radio>
                            <a-radio :value="12">个体工商户</a-radio>
                        </a-radio-group>
                    </div>
                </div>

                <div class="cm-item">
                    <div class="cmf-title">营业执照</div>
                    <div class="flex flex-left-center">
                        <upload-image
                                style="width: 200px"
                                :imageUrl="authData.business_license_info.img_url"
                                :done="busLicUploadDone"
                                :action="uploadBusLicUrl"
                                class="pay-apply-upload"
                                @del=" authData.business_license_info.img_url = '' "
                        />
                        <div class="tips ml-8">
                            <div>请上传营业执照，需年检章齐全，当年注册除外。</div>
                            <div>格式要求：原件照片、扫描件或者加盖公章的复印件，支持.jpg .jpeg
                                .png格式照片，大小不超过10M。
                            </div>
                        </div>
                    </div>
                </div>


                <div class="cm-item">
                    <div class="cmf-title">法人姓名</div>
                    <at-input v-model="authData.contact_info.name" class="full-with"/>
                </div>

                <div class="cm-item">
                    <div class="cmf-title">邮箱（接收小程序相关信息邮件）</div>
                    <at-input v-model="authData.contact_info.email" class="full-with"/>
                </div>


                <div class="cm-item">
                    <div class="cm-title">发票信息</div>
                    <div>
                        <a-radio-group v-model="authData.invoice_info.invoice_type">
                            <a-radio :value="1">不开发票</a-radio>
                            <a-radio :value="2">普通电子发票</a-radio>
                            <a-radio :value="4">增值税专票（数电类型）</a-radio>
                        </a-radio-group>
                    </div>
                </div>

                <template v-if=" authData.invoice_info.invoice_type === 2 ">
                    <div class="cm-item">
                        <div class="cmf-title">发票抬头（腾讯公司仅能开具认证主体自身抬头发票）</div>
                        <at-input disabled :value="companyName" class="full-with"/>
                    </div>
                    <div class="cm-item">
                        <div class="cmf-title">纳税识别号（15位、17、18或20位）</div>
                        <at-input v-model="authData.invoice_info.electronic.id" class="full-with"/>
                    </div>
                    <div class="cm-item">
                        <div class="cmf-title">发票备注（选填）</div>
                        <at-input v-model="authData.invoice_info.electronic.desc" class="full-with" :maxLength="20"/>
                    </div>
                </template>
                <template v-if=" authData.invoice_info.invoice_type === 4 ">
                    <div class="cm-item">
                        <div class="cmf-title">发票抬头（腾讯公司仅能开具认证主体自身抬头发票）</div>
                        <at-input disabled :value="companyName" class="full-with"/>
                    </div>
                    <div class="cm-item">
                        <div class="cmf-title">企业电话</div>
                        <at-input v-model="authData.invoice_info.vat.enterprise_phone" class="full-with"/>
                    </div>
                    <div class="cm-item">
                        <div class="cmf-title">纳税识别号（15位、17、18或20位）</div>
                        <at-input v-model="authData.invoice_info.vat.id" class="full-with" :maxLength="20"/>
                    </div>

                    <div class="cm-item">
                        <div class="cmf-title">企业注册地址</div>
                        <at-input v-model="authData.invoice_info.vat.enterprise_address" class="full-with"
                                  :maxLength="20"/>
                    </div>

                    <div class="cm-item">
                        <div class="cmf-title">企业开户银行（选填）</div>
                        <at-input v-model="authData.invoice_info.vat.bank_name" class="full-with" :maxLength="20"/>
                    </div>
                    <div class="cm-item">
                        <div class="cmf-title">企业银行账号（选填）</div>
                        <at-input v-model="authData.invoice_info.vat.bank_account" class="full-with" :maxLength="20"/>
                    </div>
                    <div class="cm-item">
                        <div class="cmf-title">发票备注（选填）</div>
                        <at-input v-model="authData.invoice_info.vat.desc" class="full-with" :maxLength="20"/>
                    </div>


                </template>


                <div>
                    <button @click="confirmApply" class="btn">提交</button>
                </div>

            </div>

            <div class="cm-form" v-if="status === 'check' ">
                <template v-if="taskInfo">
                    <div class="mb-4 text-center">本步骤认证费用由腾讯公司收取</div>
                    <a-result class="auth-result" status="success">
                        <template v-if="qrcode" #icon>
                            <img v-if="!taskInfo.apply_status" width="200px" :src="qrcode" alt="">
                        </template>
                        <template #title>
                            认证进度：{{ taskProgressMsg }}
                        </template>
                        <template #subTitle>
                            <div>任务编号：{{ taskId }}</div>

                            <template>

                                <div class="mt-4" v-if="taskInfo.apply_status === 2">
                                    <span class="text-danger">请等待人工审核！派单成功，注意接听审核来电！</span>
                                    <div style="font-size: 16px;">
                                        <span class="text-danger">微信收到认证完成消息后，请继续登录本系统进行下一步备案操作</span>
                                    </div>
                                </div>

                                <div class="mt-4" v-else-if="!taskInfo.apply_status">
                                    请企业（个体）法人在微信上完成认证流程。
                                </div>

                                <div class="mt-4" v-if="taskInfo.apply_status === 3">
                                    <span>审核单被回，请重填。原因：</span>
                                    <span> {{ taskInfo.refill_reason }}</span>
                                </div>

                                <div class="mt-4" v-if="taskInfo.apply_status === 5">
                                    <span>审核最终失败。原因：</span>
                                    <span> {{ taskInfo.refill_reason }}</span>
                                </div>

                            </template>

                        </template>
                        <template #extra>
                            <template v-if=" taskFail ">
                                <a-button @click="redo" key="console">
                                    重新认证
                                </a-button>
                            </template>
                        </template>
                    </a-result>
                </template>
                <template v-else>
                    <div class="loading-block" style="height: 200px">
                        <a-spin/>
                    </div>
                </template>
            </div>

        </div>


        <div>

        </div>


    </div>
</template>

<script>
import api from "@/repo/api";
import AtInput from "@/components/AtInput.vue";
import UploadImage from "@/components/UploadImage.vue";

export default {
    name: "Auth",
    components: {UploadImage, AtInput},
    data() {
        return {
            timerFlag: null,
            status: "none", // waite,check
            taskId: "",
            taskInfo: null,
            taskProgressMsg: "请法人微信扫码完成认证！",
            taskRejectInfo: ["", ""],
            taskFail: false,
            taskMsgList: {
                a0: '请法人微信扫码完成认证！',
                a1: '任务超时, 24小时内有效',
                a2: '用户授权拒绝',
                a3: '用户授权同意',
                a4: '发起人脸流程',
                a5: '人脸认证失败',
                a6: '人脸认证ok',
                a7: '人脸认证后，已经提交手机号码下发验证码',
                a8: '手机验证失败',
                a9: '手机验证成功',
                a11: '创建认证审核单失败',
                a12: '创建认证审核审核单成功',
                a14: '验证失败',
                a15: '等待支付'
            },
            companyName: "",
            authData: {
                app_id: null,
                account_name: "",
                account_name_type: 1,
                customer_type: 1,
                qualification: "",
                qualification_other: [],
                contact_info: {
                    name: "",
                    email: "",
                },
                business_license_info: {
                    img_url: ""
                },
                invoice_info: {
                    invoice_type: 1,
                    electronic: {
                        id: "",
                        desc: "",
                    },
                    vat: {
                        enterprise_phone: "",
                        id: "",
                        enterprise_address: "",
                        bank_name: "",
                        bank_account: "",
                        desc: "",
                    }
                },
            },
            qrcode: null,
        }
    },
    props: {
        companyInfo: Object,
        com: Boolean,
        app: Object
    },
    watch: {
        companyInfo() {
            this.loadCompanyInfo()
        }
    },
    mounted() {
        this.loadCompanyInfo()
    },
    destroyed() {
        if (this.timerFlag) {
            clearTimeout(this.timerFlag)
        }
    },
    computed: {
        uploadBusLicUrl() {
            return api.colUrl('/ast-reg/ocr-business-license')
        }
    },
    methods: {
        busLicUploadDone(res) {
            this.authData.business_license_info = res || {}
            this.loadBusinessLicenseInfo()
        },
        loadBusinessLicenseInfo() {

            this.authData.invoice_info.vat.id = this.authData.business_license_info.reg_num
            this.authData.invoice_info.electronic.id = this.authData.business_license_info.reg_num

            this.companyName = this.authData.business_license_info.enterprise_name
        },
        redo() {
            this.status = 'waite';

            if (this.timerFlag) {
                clearTimeout(this.timerFlag)
            }
        },
        loadCompanyInfo() {

            let app = this.app || this.companyInfo.ast_app

            if (!this.companyInfo) {

                this.status = 'waite'

                this.authData.app_id = app.id;

                this.authData.account_name = app.nickname || ''

                this.companyName = app.principal_name

                this.taskId = app.info.mp_verify_task_id

            }


            if (this.companyInfo) {


                if (this.companyInfo.auth_info) {

                    this.$deepMerge(this.authData, this.companyInfo.auth_info)

                    this.authData.business_license_info = this.companyInfo.business_license_info;
                    this.loadBusinessLicenseInfo();

                } else {

                    this.authData.invoice_info.vat.enterprise_address = this.companyInfo.business_license_info.address;
                    this.authData.invoice_info.vat.id = this.companyInfo.business_license_info.reg_num
                    this.authData.invoice_info.electronic.id = this.companyInfo.business_license_info.reg_num
                    this.authData.business_license_info = this.companyInfo.business_license_info;

                    this.loadBusinessLicenseInfo();
                }

                if (this.companyInfo.ast_app) {

                    if (this.companyInfo.ast_app.nickname) {
                        this.authData.account_name = this.companyInfo.ast_app.nickname
                    }

                    this.authData.app_id = this.companyInfo.ast_app.id

                    this.taskId = this.companyInfo.ast_app.info.mp_verify_task_id || ''
                }

                if (!this.authData.contact_info.name) {
                    this.authData.contact_info.name = this.companyInfo.business_license_info.legal_representative
                }
            }

            // this.status = 'waite'
            this.status = (this.taskId) ? 'check' : 'waite';

            if (this.taskId) this.getApplyStatus(true)
        },
        confirmApply() {

            this.$confirm({
                title: "请仔细核对本页填写信息，提交后不可修改",
                onOk: () => {
                    this.$loading.show()

                    api.post('/ast-reg/authentication', this.authData, (data) => {

                        this.$loading.hide();

                        if (data.code !== 0) {
                            this.$message.error(data.msg)
                            return
                        }

                        this.taskId = data.data.task_id

                        this.status = 'check'

                        if (data.data.base64_qrcode) {
                            this.qrcode = data.data.base64_qrcode
                        }

                        this.getApplyStatus(true)
                    });
                }
            })
        },
        getApplyStatus(repeat) {

            api.get('/ast-reg/auth-task-info', {
                app_id: this.authData.app_id,
                qrcode: this.qrcode ? 0 : 1,
            }, (data) => {

                this.$loading.hide();

                if (data.code !== 0) {
                    this.$message.error(data.msg)
                    return
                }

                let item = data.data;

                if (item.apply_status === 4) {

                    this.$message.success('认证通过');

                    this.$emit('success');

                }

                this.taskInfo = item

                this.taskProgressMsg = this.taskMsgList[`a${item.task_status}`]

                this.taskFail = (item.task_status === 1 || item.apply_status === 3 || item.apply_status === 5);

                if (item.base64_qrcode) {
                    this.qrcode = item.base64_qrcode
                }

                if (repeat) setTimeout(() => {

                    this.getApplyStatus(repeat)

                }, 5000)
            });
        }
    }
}
</script>

<style lang="less" scoped>

.reg-box {
    &.com {
        margin-left: 0;
        margin-top: 0;
    }
}

.reg-title {
    height: 64px;
}

.reg-selections {
    margin-top: 72px;

}

.icon-box {
    width: 80px;
    height: 80px;
    background: #F5F5F5 url("/assets/icons/xcx-icon-black.png") no-repeat center;
    background-size: 32px;
    margin-right: 16px;

    &.green {
        background: rgba(6, 222, 107, 0.2) url("/assets/icons/xcx-icon-green.png") no-repeat center;
        background-size: 32px;
    }
}

.reg-selection {
    border: 1px solid #EEEEEE;
    padding: 20px;
    width: 440px;
    margin-bottom: 32px;

    background-image: url("/assets/icons/ast-reg-right.png");
    background-repeat: no-repeat;
    background-size: 74px;
    background-position: 326px 79px;

    display: flex;


    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;

    cursor: pointer;
}

.selection-title {
    margin-top: 12px;
    margin-bottom: 12px;
}

.company-reg {
    margin-top: 72px;
    margin-left: 120px;
}

.company-reg-title {
    width: 177px;
    height: 64px;

    background-image: url("/assets/icons/compang-reg-title.png");
    background-size: 177px;
}

.ast-reg-steps {
    margin-bottom: 32px;
}

.summary-area {
    height: 120px;
}

.auth-result {
    padding-top: 0;
}
</style>