<template>
    <div class="pay-container">
        <div class="pay-logo"></div>

        <div class="step-0" v-if=" step === 0 ">
            <div class="pay-background"></div>
            <div class="loading-block">
                <a-spin :spinning="!error"/>

                <span v-if="error" class="error">没有找到所属订单信息，请联系客服。</span>
            </div>
        </div>
        <div v-else-if=" step === 1 ">
            <div class="pay-background"></div>
            <div class="pay-wrapper">

                <div class="pay-title">Hi，欢迎开通颜创形象穿搭私域主理人系统！</div>

                <div class="pay-box">
                    <div class="pay-box-line"></div>

                    <div class="pay-box-left">
                        <div class="pay-qrcode">
                            <template v-if="!qrcodeBase64">
                                <a-spin/>
                            </template>
                            <template v-else>
                                <img :src="qrcodeBase64" alt="">
                            </template>
                        </div>
                        <div class="pay-price hm-1">支付金额：<span class="price">{{ orderData.product_price }}</span> 元
                        </div>
                        <div class="pay-price hm-2">收款机构：<span>{{ orderData.owner_pt_member.name }}</span></div>
                    </div>
                    <div class="pay-box-right">
                        <div>
                            <div class="pt-title">开通产品包含：</div>
                            <ol class="pt-ol">
                                <li>形象穿搭私域系统开通及1年使用</li>
                                <template v-if=" orderData.product.detect_deduct_count > 0 ">
                                    <li>{{ orderData.product.detect_deduct_count }}个AI检测点数</li>
                                </template>
                                <template v-if=" orderData.product.wd_year_num > 0 ">
                                    <li>{{ orderData.product.wd_year_num }}个My衣橱管家年度会员</li>
                                </template>
                                <template v-if=" orderData.product.print_ts > 0 ">
                                    <li>{{ orderData.product.print_ts }}份形象实体印刷报告</li>
                                </template>
                            </ol>
                        </div>
                    </div>

                </div>

                <div class="pay-mz">
                    付款后系统自动进行权限开通；本订单由颜创科技授权代理经销商进行收款，如需合同、发票后期联系该收款机构提供
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/repo/api";

export default {
    data() {
        return {
            step: 0,
            readTime: 30,
            error: false,
            orderData: {},
            qrcodeBase64: null,
            payInfo: {},
            timerFlag: 0,
        }
    },
    mounted() {
        this.init()
    },
    destroyed() {
        clearTimeout(this.timerFlag)
    },
    methods: {
        init() {
            this.step = 0

            api.get('/ast-reg/order', (data) => {

                if (!data.data) return this.error = true;

                this.orderData = data.data

                this.step = 1

                this.createOrder()
            })
        },
        createOrder() {

            api.post('/ast-reg/mpc-pay', {id: this.orderData.id}, (data) => {

                if (data.code !== 0) return this.$message.data(data);

                this.payInfo = data.data
                this.qrcodeBase64 = data.data.qrcode

                this.checkOrderStatus()
            })
        },
        checkOrderStatus() {

            this.timerFlag = setTimeout(() => {


                api.get('/ast-reg/mpc-order-status', {no: this.payInfo.out_trade_no}, data => {

                    if(data.data.status){

                        return this.gotoReg()
                    }


                    this.checkOrderStatus()
                })


            }, 3000)

        },
        gotoReg() {
            this.$emit('finished');
        },
    }
}
</script>

<style>

html {
    font-size: 24px;
}

@media screen and (min-width: 2560px) {
    html {
        font-size: 36px;
    }
}

@media screen and (max-width: 1900px) {
    html {
        font-size: 26px;
    }
}

@media screen and (max-width: 1700px) {
    html {
        font-size: 18px;
    }
}


@media screen and (max-width: 1200px) {
    html {
        font-size: 16px;
    }
}
</style>

<style scoped lang="scss">

$reg-pay-sub-text-color: rgb(89, 87, 87);
$reg-pay-text-color-1: #757475;

@function prem($w) {
    @return $w/24 + rem;
}


//@function prem($w) {
//    @return $w+ px;
//}
.error {
    font-size: prem(26);
    color: red;
    z-index: 1;
    margin-top: 20%;
}

.pay-background {
    background-image: url("https://static.ycsd.work/collocation-helper/ast/reg-pay-bg.png");
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: fixed;
    left: 0;
    top: 0;
}

.pay-mz {
    font-size: prem(15);
    color: #FFFFFF;
    text-align: center;
    margin-top: prem(56);
    margin-bottom: prem(88);
}

.pay-title {
    font-size: prem(38);
    color: white;
    text-align: center;
    margin-top: prem(184);
}

.pay-logo {
    width: prem(308);
    height: prem(49);
    background-image: url("https://static.ycsd.work/collocation-helper/ast/yc-logo.png");
    background-size: 100%;
    position: fixed;
    top: prem(52);
    left: prem(80);
    z-index: 2;
}

.pay-container {
    color: $reg-pay-sub-text-color;
}

.pay-wrapper {
    width: prem(1445);
    margin: 0 auto;
    position: relative;
    padding: 1px;
}

.pay-box {
    height: prem(611);
    background-color: rgba(255, 255, 255, .7);
    border-radius: prem(19.1);
    margin-top: prem(45);
    position: relative;

    display: flex;

    > div {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.pay-box-line {
    width: 2px;
    height: prem(397);
    position: absolute;
    left: 50%;
    margin-left: -1px;
    background-color: $reg-pay-sub-text-color;
    top: prem(112);
    opacity: .6;
}

.pay-qrcode {
    width: prem(275);
    height: prem(275);
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;

    img {
        width: 100%;
        height: 100%;
    }
}

.pay-price {
    font-size: prem(17);
    line-height: prem(17);

    .price {
        font-size: prem(32);
        line-height: prem(32);
    }
}

.hm-1 {
    margin-top: prem(43);
}

.hm-2 {
    margin-top: prem(22);

}

.pt-title {
    font-weight: bold;
    font-size: prem(17);
    width: prem(200);
    text-align: left;
}

.pt-ol {
    padding-left: prem(16);
    margin-top: prem(38);

    li {
        margin-top: prem(22);
        font-size: prem(17);
    }
}

.pay-header {
    width: 100%;
    height: prem(213);
    background-image: url("https://static.ycsd.work/collocation-helper/ast/pay-2-header.png");
    background-size: 100% 100%;
    background-position: center;
    position: relative;
}

.pay-step-2 {
    max-width: 100%;
}

.pay-2-warpper {
    width: prem(1540);
    max-width: 100%;
    margin: 0 auto;
    padding: 1px;
    background-color: #FFFFFF;

    .pay-mz {
        color: $reg-pay-text-color-1;
        margin-top: prem(85);
    }
}

.pay-2-box {
    margin-top: prem(90);
    display: flex;

    .left, .right {
        flex: 1;
    }

    .left-box {
        width: prem(518);
    }
}

.pay-header-text {
    color: $reg-pay-text-color-1;
    font-size: prem(32);
    position: absolute;
    right: prem(331);
    top: prem(134);
}

.pay-2t1 {
    color: $reg-pay-sub-text-color;
    font-size: prem(17);
}

.pay-2t2 {
    color: $reg-pay-text-color-1;
    font-size: prem(24);
    margin-top: prem(15);
    font-weight: bold;
    line-height: 2em;
}

.pay-2t3 {
    color: $reg-pay-text-color-1;
    font-size: prem(18);
    margin-top: prem(30);

    > div {
        margin-bottom: prem(11);

        div {
            margin-top: prem(11);
            padding-left: prem(20);
        }
    }
}

.pay-2t4 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: prem(60);
    flex-direction: column;

    .tt1 {
        font-size: prem(15);
        margin-top: prem(14);
        color: $reg-pay-text-color-1;
    }
}

.reg-btn {
    width: prem(257);
    height: prem(57);
    font-size: prem(22);
    background-color: #54bcc5;
    font-weight: bold;
    text-align: center;
    line-height: prem(57);
    color: white;
    cursor: pointer;

    &.disabled {
        background-color: #dbdbdb;
        cursor: auto;
    }
}

.pt-image {
    width: prem(306);
    height: prem(403);
    background-color: #dbdbdb;
}

.pt-info {
    display: flex;
    align-items: center;
}

.pt-summary {
    margin-left: prem(123);
    flex: 1;
}

.pt-summary-title {
    color: $reg-pay-text-color-1;
    font-size: prem(24);
    font-weight: bold;
}

.pt-summary-content {
    font-size: prem(18);
    margin-top: prem(30);
    line-height: prem(40);
}

.contact-info {
    display: flex;
}

.pt-phone {
    width: prem(306);
}

.pt-qrcode {
    margin-left: prem(123);
}

.pt-phone {
    margin-top: prem(57);
    font-size: prem(22);

    > div:first-child {
        margin-bottom: prem(18);
    }
}

.pt-qrcode {
    margin-top: prem(26);
    text-align: center;
}

.pt-qrcode-image {
    width: prem(117);
    height: prem(117);
    background-color: #dbdbdb;
    margin-bottom: prem(10);
}

.sp1 {
    margin-right: prem(40);
}
</style>